/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1400px) {
    .portfolio-container {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }
    .home-hero {
        margin: 75px 0 0;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
    .header-phone {
        display: flex;
        justify-content: flex-end;
    }
    .hero__subtitle {
        display: none;
    }
    .home-hero__block {
        margin: 15vh auto ;
    }
}


/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
    .header {
     height: auto;
     padding: 5px 0;
     .navbar-nav>li {
        text-align: center;
    }
 }
 

}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {

  .header {
     height: auto;
     padding: 5px 0;
 }

 .navbar-nav>li {
    text-align: center;
}
 .home-hero__block {
    margin: 80px auto 50px;
}
 .home-hero {
    margin-left: 0;
    margin-right: 0;
    margin-top: 60px;
    overflow: hidden;
    width: 100%;
 }
 
 .hero-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 18px;
    letter-spacing: 1px;
}
.hero__subtitle {
    display: none;
}
.home-portfolio {
    overflow: hidden;
}
 .navbar-light .navbar-toggler {
   border-color: transparent;
 }
 .home-portfolio {
   overflow: hidden;
 }
 .home-portfolio__item {
    margin: 0 -15px 0;
 }
 .breadcrumbs {
     display: none;
 }
 .section {
    padding: 50px 0;
}
.homelid__form {
    padding: 20px;
    margin-top: 30px;
    margin-left: -15px;
    margin-right: -15px;
    box-shadow: none;
}
.home-contact__box {
    padding: 0;
    margin: 0;
}
.portfolio-container {
    width: 100%;
    .big-title {
        margin: 0 15px 18px;
    }
}
.line-tree {
    h3 {
        margin-top: 25px;
    }
}
.copyright-box {
    .socials {
        text-align: left;
        ul {
            text-align: left;
            margin-left: 10px;
        }
    }
}

.btn-callback {
    display: none;
}
.counter {
    display: none;
}
.saitnavchera_box {
    display: none;
}

.big-title,
.sub-title,
.service-block {
    text-align: left;
}
.home-portfolio__top {
    text-align: left;
    margin-bottom: 20px;
}
.request-container {
    padding: 0;
    margin: 0 -15px;
    text-align: center;
}
.counter__item {
    margin-bottom: 30px;
}
.text-col-2 {
    column-count: 1;
}
.page-title-theme h1,
.page-title-theme_one-service h1 {
    text-align: left;
}
.business-page_txt-line,
.business-page_process {
    text-align: left;
}
.business-page_planes-box {
    margin-bottom: 20px;
}
.service-special-link {
    h2, p {
        text-align: left;
    }
}
 #footer {
    overflow: hidden;
    text-align: left;
}

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

    }

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

    }


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

    }

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

    }

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

    }

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

    }

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    .header__container .container {
        padding: 0 10%;
        max-width: 100%;
    }
}
