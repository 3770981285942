@-webkit-keyframes Gradient {
    0%  {background-position:0% 50%}
    50% {background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes Gradient {
	0%  {background-position:0% 50%}
    50% {background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes Gradient {
	0%  {background-position:0% 50%}
    50% {background-position:100% 50%}
    100%{background-position:0% 50%}
}




  .service-block:hover .rotateIn {
    -webkit-animation-name: rotateIn;
    animation-name: rotateIn;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes rotateIn {
    0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 1;
    }
    100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
    }
    }
    @keyframes rotateIn {
    0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 1;
    }
    100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
    }
    }



/* Portfolio */
.animate-block {
    position: relative;
}
.animate-block::before, .animate-block::after {
  box-sizing: inherit;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}
.draw {
  transition: color 0.25s;
}
.draw::before, .draw::after {
  border: 6px dashed transparent;
  width: 0;
  height: 0;
}
.draw::before {
  top: 0;
  left: 0;
}
.draw::after {
  bottom: 0;
  right: 0;
}
.draw:hover {

}
.draw:hover::before, .draw:hover::after {
  width: 100%;
  height: 100%;
}
.draw:hover::before {
  border-top-color: #0044cc;
  border-right-color: #0044cc;
  transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
}
.draw:hover::after {
  border-bottom-color: #0044cc;
  border-left-color: #0044cc;
  transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s, height 0.25s ease-out 0.75s;
}

/* callback-btn */
#popup__toggle {
  bottom: 25px;
  left: 10px;
  position: fixed;
  z-index: 500;
}
.img-circle {
  background-color: $color-link;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
}
.circlephone {
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  border: 2px solid $color-link;
  width: 150px;
  height: 150px;
  bottom: -25px;
  left: 10px;
  position: absolute;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  opacity: 0.5;
  -webkit-animation: circle-anim 2.4s infinite ease-in-out !important;
  -moz-animation: circle-anim 2.4s infinite ease-in-out !important;
  -ms-animation: circle-anim 2.4s infinite ease-in-out !important;
  -o-animation: circle-anim 2.4s infinite ease-in-out !important;
  animation: circle-anim 2.4s infinite ease-in-out !important;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.circle-fill {
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  background-color: $color-link;
  width: 100px;
  height: 100px;
  bottom: 0px;
  left: 35px;
  position: absolute;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  -webkit-animation: circle-fill-anim 2.3s infinite ease-in-out;
  -moz-animation: circle-fill-anim 2.3s infinite ease-in-out;
  -ms-animation: circle-fill-anim 2.3s infinite ease-in-out;
  -o-animation: circle-fill-anim 2.3s infinite ease-in-out;
  animation: circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.img-circle {
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  width: 72px;
  height: 72px;
  bottom: 14px;
  left: 49px;
  position: absolute;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.7;
}
.img-circleblock {
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  width: 72px;
  height: 72px;
  background-position: center center;
  background-repeat: no-repeat;
  animation-name: tossing;
  -webkit-animation-name: tossing;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
.img-circle:hover {
  opacity: 1;
}
@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 1;
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.95);
    opacity: 1;
  }
}
@keyframes tossing {
  0% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(-8deg);
  }
}
@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-8deg);
  }
  50% {
    -webkit-transform: rotate(8deg);
  }
  100% {
    -webkit-transform: rotate(-8deg);
  }
}
@-moz-keyframes circle-anim {
  0% {
    -moz-transform: rotate(0deg) scale(0.5) skew(1deg);
    opacity: 0.1;
    -moz-opacity: 0.1;
    -webkit-opacity: 0.1;
    -o-opacity: 0.1;
  }
  30% {
    -moz-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.5;
    -moz-opacity: 0.5;
    -webkit-opacity: 0.5;
    -o-opacity: 0.5;
  }
  100% {
    -moz-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.6;
    -moz-opacity: 0.6;
    -webkit-opacity: 0.6;
    -o-opacity: 0.1;
  }
}
@-webkit-keyframes circle-anim {
  0% {
    -webkit-transform: rotate(0deg) scale(0.5) skew(1deg);
    -webkit-opacity: 0.1;
  }
  30% {
    -webkit-transform: rotate(0deg) scale(0.7) skew(1deg);
    -webkit-opacity: 0.5;
  }
  100% {
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    -webkit-opacity: 0.1;
  }
}
@-o-keyframes circle-anim {
  0% {
    -o-transform: rotate(0deg) kscale(0.5) skew(1deg);
    -o-opacity: 0.1;
  }
  30% {
    -o-transform: rotate(0deg) scale(0.7) skew(1deg);
    -o-opacity: 0.5;
  }
  100% {
    -o-transform: rotate(0deg) scale(1) skew(1deg);
    -o-opacity: 0.1;
  }
}
@keyframes circle-anim {
  0% {
    transform: rotate(0deg) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.1;
  }
}
@-moz-keyframes circle-fill-anim {
  0% {
    -moz-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    -moz-transform: rotate(0deg) -moz-scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    -moz-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}
@-webkit-keyframes circle-fill-anim {
  0% {
    -webkit-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    -webkit-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}
@-o-keyframes circle-fill-anim {
  0% {
    -o-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    -o-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    -o-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}
@keyframes circle-fill-anim {
  0% {
    transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}
.img-circleblock img {
  display: block;
  width: 50px;
  margin: 10px auto;
}
/* callback-btn */