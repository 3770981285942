.header {
    padding: 10px 0;
    background: #fff;
    // @include gradient;
    height: 75px;
    &.header-shadow {
        // box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.3);
        border-bottom: 1px solid #e6e6e6;
    }
    &.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 500;
    }
    
    .navbar {
        padding: 0;
    }
}

.header-phone {
    float: right;
}
.header-navbar {
    margin: 0;
    padding: 0;
    .dropdown:hover>.dropdown-menu {
        display: block;
      }
      
      .dropdown>.dropdown-toggle:active {
        /*Without this, clicking will make it sticky*/
          pointer-events: none;
      }
}

.header-nav .nav-link {
    font-size: 14px;
    text-transform: uppercase;
    color: $color-title;
    font-weight: bold;
    padding: 8px;
    &:hover {
      color: #0055cc;
    }
}

.header-phone__items {
    margin: 0;
    padding: 12px 0 0 28px;
    list-style: none;
    position: relative;
    &:before {
        content: '\f10b';
        font-family: 'FontAwesome';
        position: absolute;
        color: $color-title;
        left: 0;
        top: 11px;
        font-size: 36px
    }
}

.header-phone__item {
    font-size: 14px;
    line-height: 24px;
    color: $color-title;
    list-style: none;
}

.header-phone__link {
    text-decoration: none;
    color: $color-title;
    &:hover {
        text-decoration: none;
        color: $color-title;
    }
}

.header__btn-request {
    margin-top: 7px;
    padding: 10px;
    float: right;
    &--phone {
        float: none;
    }
}
.navbar-nav .dropdown-menu {
    border-radius: 0;
}
