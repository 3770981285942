@font-face {
  font-family: 'Raleway-Bold';
  src: url('../fonts/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Raleway-Bold.woff') format('woff'),
  url('../fonts/Raleway-Bold.ttf')  format('truetype'),
  url('../fonts/Raleway-Bold.svg#Raleway-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i&amp;subset=cyrillic"); */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,500,700,700i&display=swap&subset=cyrillic');
@import "vars";
@import "animated";
@import "canvas";

/*@import 'top-navigation';*/

body {
  color: $color-text;
  font-family: $font-base;
  font-weight:300;
  font-size: 16px;
  line-height: 24px;
}
p {
  color: $color-text;
  font-size: 16px;
  line-height: 26px;
}
a {
  color: $color-link;
  &:hover {
    color: #0055cc;
  }
}
strong {
  font-weight: 500;
}
.iw {
  img {
    width: 100%;
  }
}
.btn {
  border-radius: 0;
}
.btn-default {
  color: $color-main;
  @include gradient;
  border-color: transparent;
  border: 0;
  font-size: 12px;
  font-family: $font-title;
  padding: 12px 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  @include bnt-shadow-hover;
  transition: all 0.3s ease;
  margin-top: 0;
  margin-bottom: 0;
  &--big {
    padding: 18px 45px;
  }
}
.btn-default:hover,
.btn-default:active,
.btn-default:focus,
.btn-default:focus:active {
  @include gradient;
  color: $color-main;
  outline: none;
  @include bnt-shadow;
  transition: all 0.2s ease;
  margin-top: -2px;
  margin-bottom: 2px;
}
.btn-box {
  margin-top: 30px;
  height: 50px;
}
.btn-request {
  color: #0055cc;
  border: 1px solid #0055cc;
  background: $color-main;
  font-size: 12px;
  font-weight: bold;
  border-radius: 0px;
  padding: 10px 25px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.5s ease;
  position: relative;
  overflow: hidden;
  z-index: 20;
  span {
    position: relative;
    z-index: 30;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -101%;
    bottom: 0;
    width: 101%;
    @include gradient;
    transition: all 0.5s ease;
    z-index: 10;
  }
  &:hover {
    color: #fff;
  }
  &:hover::after {
    left: 0;
    transition: all 0.5s ease;
  }
}
.btn-white {
  font-size: 12px;
  font-family: $font-title;
  border-radius: 0px;
  padding: 12px 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid #fff;
  color: $color-main;
  transition: all 0.5s ease;
  position: relative;
  overflow: hidden;
  z-index: 20;
  span {
    position: relative;
    z-index: 30;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    bottom: 0;
    width: 100%;
    background: $color-main;
    transition: all 0.5s ease;
    opacity: 1;
    z-index: -10;
  }
  &:hover {
    color: #0055cc;
  }
  &:hover::after {
    left: 0;
    transition: all 0.5s ease;
  }
}
.btn-big {
  padding: 18px 45px;
}
.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}
/*** page Title ***/
.hero-title {
  color: $color-white;
  font-family: $font-title;
  font-size: 40px;
  font-weight: bold;
  line-height: 52px;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 2px;
}
.hero-subtitle {
  color: $color-white;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  // text-transform: uppercase;
  margin-bottom: 18px;
  letter-spacing: 2px;
}

.big-title {
  color: $color-title;
  font-family: $font-title;
  font-size: 42px;
  font-weight: bold;
  line-height: 42px;
  margin-bottom: 24px;
  letter-spacing: 1px;
}
.section {
  padding: 100px 0;
  overflow: hidden;
  &--ntp.section {
    padding: 0 0 100px;
    margin-top: -40px;
  }
}
.border-dashed {
  border-right: 1px dashed #dfdfdf;
}
.text-col-2 {
  column-count: 2;
  column-gap: 65px;
}
.text-scroll {
  max-height: 410px;
  overflow-y: auto;
  padding-right: 20px;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #e5f4ff;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bae1ff;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #a1d6ff;
  }
}
.form-control {
  border-radius: 0;
}

@import "header";

/*** Hero ***/
.home-hero {
  margin: 75px 5% 0;
  position: relative;
  @include gradient-hero3;
  background-size: 600% 600%;
  animation: Gradient 16s ease infinite;
  transition-duration: 0.95s;
  transition-property: left;
  transition-delay: 0s;
  transition-timing-function: "ease-in-out";
  &__block {
    margin: 15vh auto 0;
  }
  &__title {
    color: #fff;
    font-size: 14px;
    line-height: 26px;
  }
}
.hero__subtitle {
  color: #fff;
  margin: 15vh 0 30px;
  .fa {
    font-size: 32px;
    margin-bottom: 15px;
  }
  p {
    color: #fff;
    text-transform: uppercase;
  }
}

.portfolio-page {
  margin: 0 15px;
}

/* home-services */
.service-block {
  text-align: center;
  &:hover {
    .service-block__icon {
      margin: 40px 0 50px;
      transition: all 0.5s ease;
    }
  }
  &__icon {
    margin: 60px 0 30px;
    transition: all 0.5s ease;
  }
  &__title {
    font-family: $font-title;
    color: $color-title;
    font-size: 21px;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  &__items {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &__item {
    list-style: none;
    margin: 0;
    padding: 4px 0;
    position: relative;
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: "";
      width: 100%;
      border-bottom: 1px solid #04c;
      transform: scaleX(0);
      transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
    }
    &:hover::after {
      transform: scaleX(1);
      transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
    }
  }
  &__link {
    color: $color-text;
    font-size: 16px;
    &:hover {
      text-decoration: none;
    }
  }
}

/* request */
.home-request {
  color: #fff;
  overflow: hidden;
  .big-title {
    color: #fff;
  }
}
.home-request__box {
  padding: 60px 0;
}
.request-container {
  margin: 0 auto;
  overflow: hidden;
  padding: 20px 30px 55px;
}
.request-container-bg {
  position: relative;
  width: 100%;
  height: 100%;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    width: 100%;
    // border-radius: 4px;
    @include gradient;
    transition-duration: 0.95s;
    transition-property: left;
    transition-delay: 0s;
    transition-timing-function: "ease-in-out";
    box-shadow: 0 20px 40px rgba(2, 117, 216, 0.3);
  }
  &.loaded::before {
    content: "";
    left: 0;
    transition-duration: 0.95s;
    transition-property: left;
    transition-delay: 0s;
    transition-timing-function: "ease-in-out";
  }
}

/* home-portfolio */
.home-portfolio {
  padding: 0;
  overflow: hidden;
}
.portfolio-container {
  width: 80%;
  margin: 0 auto;
}
.home-portfolio__top {
  text-align: center;
  margin-bottom: 60px;
}
.home-portfolio__items {
  display: flex;
}
.home-portfolio__item {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  img {
    width: 100%;
  }
}

.home-portfolio__item-black {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}
.home-portfolio__item .project-hover {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  text-align: center;
  display: block;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.home-portfolio__item {
  &:hover .project-hover {
    height: 100%;
    @include gradient;
    // opacity: 0.8;
    display: block;
  }
}
.home-portfolio__item .project-hover .project-home-description {
  color: #fff;
  margin-top: 25%;
  display: block;
  text-transform: uppercase;
  text-align: center;
}
.home-portfolio__item .project-hover .project-home-description i + span {
  font-size: 18px;
  display: block;
  margin: 15px 0 10px;
}
.home-portfolio__item .project-hover .project-home-description i {
  font-size: 21px;
}
.home-portfolio__more {
  text-align: center;
  margin-top: 20px;
}

/* section home-review */
.home-review {
  padding-bottom: 0;
  // background: url(../images/paralax-bg.jpg) center no-repeat;
  // background-size: cover;
  // background-attachment: fixed;
  // position: relative;
  // z-index: 20;
  // &__bg {
  //   @include grad-darkpurple;
  //   opacity: 0.3;
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   width: 100%;
  //   z-index: 30;
  // }
  &__container {
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 40;
    overflow: hidden;
  }
  &__item {
    text-align: left;
  }
  &__top {
    text-align: left;
    margin-bottom: 40px;
  }
  &__text {
    color: #fff;
    margin: 40px 0 20px;
  }
  &__author {
    color: #fff;
  }
}
.j-review-slider {
  position: static;
}
.swiper-button-prev.j-review-prev {
  z-index: 20;
  height: 54px;
  width: 20px;
  left: -60px;
  top: 40%;
  .fa {
    font-size: 56px;
    color: #fff;
  }
}
.swiper-button-next.j-review-next {
  z-index: 20;
  height: 54px;
  width: 20px;
  right: -60px;
  top: 40%;
  .fa {
    font-size: 56px;
    color: #fff;
  }
}
.review-item {
  text-align: left;
  font-style: italic;
  &__author {
    border-top: 1px solid #e6e6e6;
    margin-top: 20px;
    padding-top: 10px;
    font-weight: bold;
  }
  &__text {
    position: relative;
    text-indent: 45px;
    padding-top: 8px;
    &::before {
      content: '\f10d';
      font-family: FontAwesome;
      font-weight: 400;
      font-size: 38px;
      font-style: normal;
      position: absolute;
      top: 4px;
      left: -45px;
      height: 20px;
      width: 20px;
      color: #e6e6e6;
    }
  }
}

.homelid,
.contactpage {
  // background: #fafafa;
  &__form {
    border: 12px solid #058cd9;
    padding: 50px;
    box-shadow: 0 20px 40px rgba(2, 117, 216, 0.3);
    .custom-control-label::before {
      top: 5px;
    }
    .custom-control {
      margin-bottom: 7px;
      label {
        line-height: 24px;
      }
    }
    &-btn {
      margin-top: 15px;
    }
  }
}
.counter {
  position: relative;
  @include gradient-hero3;
  background-size: 600% 600%;
  animation: Gradient 16s ease infinite;
  transition-duration: 0.95s;
  transition-property: left;
  transition-delay: 0s;
  transition-timing-function: "ease-in-out";
  padding: 70px 0;
  &__item {
    text-align: center;
    color: #fff;
  }
  &__header {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &__footer {
    font-size: 21px;
    font-weight: normal;
    text-transform: uppercase;
  }
}
// .home-about {
//   padding-bottom: 0;
// }

.home-contact__box {
  background: #fff;
  padding: 50px;
  transition: all 0.5s ease;
  margin: 50px 0 0;
  &:hover {
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.3);
    transition: all 0.5s ease;
    margin: 0 0 50px;
  }
}

.home-contact__form {
  position: relative;
  z-index: 100;
}

.project-info {
  &__item {
    margin-bottom: 30px;
  }
}

.i-store-slider {
  img {
    width: 100%;
  }
}

.allservice {
  margin: 30px 0;
  &__line {
    border-bottom: 1px solid #f0f0f0;
    margin: 80px 0 40px;
  }
}
.service-tabs-block {
  margin-bottom: 60px;
}

.breadcrumb {
  background: transparent;
  padding: .75rem 0;
  color: #999;
  font-size: 12px;
  text-transform: uppercase;
  a {
    color: #999;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
      color: #999;
      text-decoration: underline;
    }
  }
}
.breadcrumb-item.active {
  color: #999;
}

/*** To Top ***/
a.btn-up {
  position: fixed;
  bottom: 100px;
  right: 30px;
  width: 45px;
  height: 45px;
  display: none;
}
a.btn-up {
  background: rgba(255, 255, 255, 0.7);
  display: block !important;
}
a.btn-up:after {
  content: "\f106";
  font-family: FontAwesome;
  font-style: normal;
  font-size: 30px;
  font-weight: normal;
  text-decoration: inherit;
  color: #0275d8;
  position: absolute;
  top: 20%;
  left: 28%;
  /*top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);*/
}
a.btn-up:hover:after {
  color: #143373;
  text-decoration: none;
}
a.btn-up:active,
a.btn-up:focus {
  text-decoration: none;
  outline: none;
}

@import "footer";

@import "style-old";

//ALWAYS END
@import "media";
