.footer {
    background: #18181E;
    color: $color-grey-light;
    padding: 80px 0 50px;
    .nav-item {
        display: block;
        width: 100%
    }
    .nav-link {
        width: 100%;
        text-align: left;
        padding: 4px;
        font-weight: normal;
    }
}

.footer-phone__items {
    margin: 0;
    padding: 0 0 0 28px;
    list-style: none;
    position: relative;
    color: $color-grey-light;
    &:before {
        content: '\f10b';
        font-family: 'FontAwesome';
        position: absolute;
        color: $color-grey-light;
        left: 0;
        top: -8px;
        font-size: 36px
    }
}

.footer-email__items {
    margin: 0;
    padding: 0 0 0 36px;
    list-style: none;
    position: relative;
    &:before {
        content: '\f003';
        font-family: 'FontAwesome';
        color: $color-grey-light;
        position: absolute;
        left: 0;
        top: -2px;
        font-size: 24px
    }
}

.footer-phone__link,
.footer-email__link {
    text-decoration: none;
    color: $color-grey-light;
    &:hover {
        text-decoration: none;
        color: $color-grey-light;
    }
}

.menu-footer {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      line-height: 21px;
      margin-bottom: 10px;
      list-style: none;
      a {
        font-size: 14px;
        color: $color-grey-light;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
          }
      }
    }
  }

  .menu-footer .title {
    font-size: 18px;
    font-family: $font-title;
    padding: 0;
    margin: 0 0 15px;
    text-transform: uppercase;
  }
  .footer_adress .fa {
    float: left;
    margin-top: 4px;
  }
  .footer_adress-li {
    margin-left: 22px;
  }
  .copyright-box .socials {
    margin: 10px auto;
    text-align: center;
  }
  
  .copyright-box .socials ul {
    overflow: hidden;
    text-align: center;
    margin: 0 auto;
    padding: 0;
  }
  
  .copyright-box .socials ul li {
    display: inline-block;
    vertical-align: middle;
    padding: 0 7px;
  }
  
  .copyright-box .socials ul li a {
    font-size: 24px;
    color:$color-grey-light;
  }
  
  .copyright-box .socials ul li a:hover {
    font-size: 24px;
    color: #0275d8;
  }
  
  .copyright-box {
    background: #18181E;
    text-align: center;
    padding: 15px 0;
    color: $color-grey-light;
    border-top: 1px solid #333;
    p {
      color: $color-grey-light;
    }
  }