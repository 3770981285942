//Основные цвета
$color-main: #fff; // основной цвет
$color-dop: #d04a6b; // дополнительный цвет
$color-danger: #D41131; // цвет ошибки, опасного действия
$color-success: #3FD411; // цвет подтверждения, удачного завершения операции
$color-warning: #D8AE1C; // цвет информационного сообщения
$color-text: #4d4d4d;
$color-link: #0044cc;
$color-hover: #FE5000;
$color-title: #45474d;
$color-grey-light: #d4d4d4;
$color-white: #fff;
$color-bg: #edf6ff;
$font-base: 'Roboto', sans-serif;
$font-title: 'Raleway-Bold', sans-serif;
@mixin gradient {
    background: -moz-linear-gradient(45deg, #0044cc 0%, #7a45e5 100%);
    background: -webkit-linear-gradient(45deg, #0044cc 0%, #7a45e5 100%);
    background: linear-gradient(45deg, #0044cc 0%, #7a45e5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0044cc', endColorstr='#7a45e5', GradientType=1);
}

@mixin gradient-hero {
    background: #0044cc;
    background: -moz-linear-gradient(45deg, #0022cc 0, #7733ff 64%, #1a8cff 100%);
    background: -webkit-linear-gradient(45deg, #0022cc 0, #7733ff 64%, #1a8cff 100%);
    background: linear-gradient(45deg, #0022cc 0, #7733ff 64%, #1a8cff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0022cc', endColorstr='#7733ff', GradientType=1);
}

@mixin gradient-hero3 {
    background: #0044cc;
    background: -moz-linear-gradient(45deg, #0022cc 0, #7B1FA2 64%, #1a8cff 100%);
    background: -webkit-linear-gradient(45deg, #0022cc 0, #7B1FA2 64%, #1a8cff 100%);
    background: linear-gradient(45deg, #0022cc 0, #7B1FA2 64%, #1a8cff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0022cc', endColorstr='#7B1FA2', GradientType=1);
}

@mixin gradient-hero2 {
    background: #0044cc;
    background: -moz-linear-gradient(45deg, #0022cc 0, #7733ff 100%);
    background: -webkit-linear-gradient(45deg, #0022cc 0, #7733ff 100%);
    background: linear-gradient(45deg, #0022cc 0, #7733ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0022cc', endColorstr='#7733ff', GradientType=1);
}


/* Gradient */

@mixin grad-darkpurple {
    background: #003242;
    background: -moz-linear-gradient(45deg, rgba(0, 50, 66, 1) 0, rgba(32, 0, 96, 1) 50%, rgba(79, 0, 92, 1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(0, 50, 66, 1) 0, rgba(32, 0, 96, 1) 50%, rgba(79, 0, 92, 1) 100%);
    background: linear-gradient(45deg, rgba(0, 50, 66, 1) 0, rgba(32, 0, 96, 1) 50%, rgba(79, 0, 92, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003242', endColorstr='#4f005c', GradientType=1);
}

@mixin grad-blue {
    background: #8200f4;
    background: -moz-linear-gradient(45deg, rgba(130, 0, 244, 1) 0, rgba(37, 247, 255, 1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(130, 0, 244, 1) 0, rgba(37, 247, 255, 1) 100%);
    background: linear-gradient(45deg, rgba(130, 0, 244, 1) 0, rgba(37, 247, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8200f4', endColorstr='#25f7ff', GradientType=1);
}

@mixin grad-midblue {
    background: #350096;
    background: -moz-linear-gradient(45deg, #350096 0, #00e3c6 100%);
    background: -webkit-linear-gradient(45deg, #350096 0, #00e3c6 100%);
    background: linear-gradient(45deg, #350096 0, #00e3c6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#350096', endColorstr='#00e3c6', GradientType=1);
}

@mixin grad-darkblue {
    background: #23075d;
    background: -moz-linear-gradient(45deg, #23075d 0, #00605c 100%);
    background: -webkit-linear-gradient(45deg, #23075d 0, #00605c 100%);
    background: linear-gradient(45deg, #23075d 0, #00605c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#23075d', endColorstr='#00605c', GradientType=1);
}

@mixin grad-purple {
    background: #2a0aa9;
    background: -moz-linear-gradient(45deg, rgba(42, 10, 169, 1) 0, rgba(124, 19, 157, 1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(42, 10, 169, 1) 0, rgba(124, 19, 157, 1) 100%);
    background: linear-gradient(45deg, rgba(42, 10, 169, 1) 0, rgba(124, 19, 157, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2a0aa9', endColorstr='#7c139d', GradientType=1)
}

@mixin grad-pink {
    background: #fa49ff;
    background: -moz-linear-gradient(45deg, #fa49ff 0, #6267fc 64%, #5bead5 100%);
    background: -webkit-linear-gradient(45deg, #fa49ff 0, #6267fc 64%, #5bead5 100%);
    background: linear-gradient(45deg, #fa49ff 0, #6267fc 64%, #5bead5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fa49ff', endColorstr='#5bead5', GradientType=1);
}

@mixin grad-red {
    background: #221884;
    background: -moz-linear-gradient(45deg, #221884 0, #be3679 50%, #ffa458 100%);
    background: -webkit-linear-gradient(45deg, #221884 0, #be3679 50%, #ffa458 100%);
    background: linear-gradient(45deg, #221884 0, #be3679 50%, #ffa458 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#221884', endColorstr='#ffa458', GradientType=1);
}


/* Shadow */

@mixin bnt-shadow {
    -webkit-box-shadow: 0px 8px 24px 0px rgba(18, 115, 186, 0.5);
    -moz-box-shadow: 0px 8px 24px 0px rgba(18, 115, 186, 0.5);
    box-shadow: 0px 8px 24px 0px rgba(18, 115, 186, 0.5);
}

@mixin bnt-shadow-hover {
    -webkit-box-shadow: 0px 2px 10px 0px rgba(18, 115, 186, 0.5);
    -moz-box-shadow: 0px 2px 10px 0px rgba(18, 115, 186, 0.5);
    box-shadow: 0px 2px 10px 0px rgba(18, 115, 186, 0.5);
}
