
.container {
  /*     max-width: 1170px; */
  margin: 0 auto;
}
.index-form {
  color: #0275d8;
}
.index-form .errors {
  color: red;
}
.container:after {
  content: "";
  display: block;
  clear: both;
}

.btn-small {
  border-radius: 0;
  padding: 15px 50px;
  text-transform: uppercase;
}

.btn-outline-white {
  border-color: #fff;
  color: #fff;
}

.btn-outline-white:hover {
  color: #0275d8;
  background: #fff;
}


.justify-center {
  text-align: justify;
  text-align-last: center;
}

.light-grey-bg {
  background: #f5f5f5;
}

.blue-bg {
  background: #0275d8;
}

.white-bg {
  background: #fff;
}

.shadow-box {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
}

.section-space {
  padding-top: 50px;
  padding-bottom: 60px;
}
.inside-box {
  padding: 50px;
}

.light-grey-box {
  background: #f5f5f5;
  padding-top: 50px;
  padding-bottom: 60px;
  margin: 60px 0 0;
}

.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.column {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
}
textarea {
  resize: none;
}
.service-page-all {
  padding: 0;
  overflow: hidden;
}

/* HEADER */

#wrapper {
  overflow: hidden;
}

.menu-header {
  padding: 11px 0 10px;
}
.navbar-nav {
  float: none;
  display: flex;
  display: -webkit-flex;
  display: -webkit-block;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
}

.nav > li > a {
  padding: 10px;
}

.navbar-nav > li > a {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  line-height: 21px;
}

.nav > li > a:focus,
.nav > li > a:hover {
  background: none;
  color: #0095eb;
}

.header.dark-header .nav > li > a:focus,
.nav > li > a:hover {
  background: none;
  color: #b2dbff;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background: none;
}

.heading-box {
  margin: 0 0 20px;
}

a.blue-btn {
  color: #fff;
  text-transform: uppercase;
  background: #0095eb;
  padding: 9px 30px;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  /* border-radius: 2px;*/
  border: 1px solid #0095eb;
}

.btn-outer {
  text-transform: uppercase;
  padding: 9px 30px;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.btn-outer:hover {
  color: #0095eb;
  background: #fff;
}

// .btn-white {
//   text-transform: uppercase;
//   padding: 15px 50px;
//   text-align: center;
//   font-weight: normal;
//   font-size: 14px;
//   background: transparent;
//   border: 1px solid #fff;
//   color: #fff;
// }

// .btn-white:hover {
//   color: #0095eb;
//   background: #fff;
// }

a.blue-btn:hover,
a.blue-btn:focus {
  text-decoration: none;
  background: transparent;
  color: #0095eb;
}

.dark-header a.blue-btn {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
}

.dark-header a.blue-btn:hover {
  border: 1px solid #fff;
  color: #0275d8;
  background: #fff;
}

.menu-header {
  overflow: hidden;
}

#navbar {
  float: right;
}

.sign-up {
  margin-top: 18px;
}

.header-inner.dark-header .sign-up {
  margin-top: 18px;
}

.phone-tab {
  text-align: center;
  margin-top: 5px;
  padding: 0 8px;
}
.phone-tab .nav-tabs {
  border-bottom: 1px dashed #fff;
}
.phone-tab .nav > li {
  position: relative;
  display: inline-block;
  float: none;
}
.phone-tab .nav > li > a {
  padding: 4px 10px;
  text-transform: uppercase;
  color: #fff;
  opacity: 0.5;
  margin-right: 0;
}
.phone-tab .nav-tabs > li.active > a,
.phone-tab .nav-tabs > li.active > a:focus,
.phone-tab .nav-tabs > li.active > a:hover {
  color: #fff;
  background: transparent;
  border: 0;
  opacity: 1;
}

.phone-tab .nav-tabs > li > a:hover,
.phone-tab .nav-tabs > li > a {
  border: 0;
}
.phone-tab .tab-pane a {
  color: #fff;
}

/* HEADER END */

.video-box {
  overflow: hidden;
  height: 630px;
  width: 100%;
  position: relative;
  background: url(../video/image-video.jpg) no-repeat 50% 0;
  background-size: cover;
  z-index: 0;
}

.video-box .video-box-out {
  background-position: 50% 0 !important;
  text-align: center;
  z-index: 0 !important;
  position: relative;
}

.video-box:before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.video-box video {
  width: 100%;
  margin: -9% 0 0;
}

.video-box .video-box-title {
  position: absolute;
  top: 30%;
  text-align: center;
  color: #fff;
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  z-index: 10;
  width: 100%;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
}

.video-box .video-box-title h2 {
  color: #fff;
  font-size: 40px;
  line-height: 48px;
  font-weight: normal;
}

.video-box .video-box-title h2 + div {
  margin: 30px 0 60px;
}

.video-box .video-box-title .video-box-btn a {
  margin: 0 15px;
}

.video-box .video-box-title .video-box-btn .blue-btn,
.video-box .video-box-title .video-box-btn .btn-outer {
  /*width: 180px;*/
  display: inline-block;
}

.our-skills {
  background: #f5f5f5;
  padding: 20px 0;
  text-align: center;
  max-height: 152px;
  overflow: hidden;
}

.our-skills ul {
  overflow: hidden;
  text-align: center;
}

.our-skills ul li {
  display: inline-block;
  margin-right: 15px;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  border-bottom: 2px solid #cdd1d2;
}

.our-skills-box {
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  border-bottom: 2px solid #cdd1d2;
  margin: 0 8px;
}

.our-skills ul li img {
  vertical-align: top;
}

.hero-black {
  width: 1920px;
  height: 1080px;
  background: rgba(0, 0, 0, 0.5);
}

.hero-title h1 {
  font-size: 42px !important;
  line-height: 50px !important;
  color: #fff;
  font-weight: 100 !important;
  text-transform: uppercase !important;
  font-family: "Lato", sans-serif !important;
}

.hero-title h1 span.blue {
  /*color: #0275d8;*/
  font-size: 42px !important;
  text-transform: uppercase !important;
  font-weight: 300 !important;
  font-family: "Lato", sans-serif !important;
}

.rev-scroll-btn {
  border-width: 1px !important;
}

/*** whore-clients-box ***/

.whore-clients-box {
  background: #0275d8;
  padding: 3% 6%;
  color: #fff;
}

.whore-clients-bgimage {
  background: url(../images/whore-bg.jpg) 100% 0% no-repeat;
  background-size: cover;
}

.whore-clients-box h2,
.whore-clients-box p {
  color: #fff;
  text-align: center;
}

.whore-clients-box .dot-line {
  border-color: #fff;
  margin: 25px auto;
}

.whore-clients-box .dot-line + p {
  margin-bottom: 20px;
}

.whore-clients-box ul {
  margin: 0;
  padding: 0;
}

.whore-clients-box .whore-box {
  margin: 0 0 40px;
  min-height: 80px;
  text-align: left;
  padding: 0 15px;
}

.whore-clients-box .whore-box img {
  float: left;
}

.whore-clients-box .whore-box img + div {
  margin-left: 50px;
}

.whore-clients-box .whore-box h4 {
  text-transform: uppercase;
}

.whore-clients-box .whore-box p {
  text-align: left;
}

/*** services-home ***/

.services-home {
  text-align: center;
  padding: 60px 0;
}

.servces-box {
  padding: 0 20px;
}

.servces-box:hover img {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

.servces-box ul li {
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.servces-box ul li:hover {
  background: #f0f0f0;
}

.our-services {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 80px;
}

.our-services h3 {
  border-bottom: 1px solid #0275d8;
  padding-bottom: 10px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
}

.our-services .text-center {
  margin-top: 50px;
}

.services-home .dot-line + p {
  margin-bottom: 40px;
}

.services-home .servces-box h4 {
  margin: 15px;
  color: #000;
  text-transform: uppercase;
}

/*** Parallax ***/

.paralax-home {
  background: url(../images/paralax-bg.jpg) 50% 0 no-repeat fixed;
  padding: 70px 0;
}

.paralax-home h2 {
  text-align: center;
}

/*** Our Projects ***/

.our-projects-home-title {
  background: #f0f0f0;
}

.our-projects-home h2 {
  text-align: center;
  margin: 35px;
}

.our-projects-home-line .col-md-4 {
  padding-left: 0;
  padding-right: 0;
}

.our-projects-home-box {
  position: relative;
  overflow: hidden;
}

.our-projects-home-box img {
  width: 100%;
}

.our-projects-home-box .project-hover {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  text-align: center;
  display: block;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.our-projects-home-box:hover .project-hover {
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: block;
}

.our-projects-home-box .project-hover .project-home-description {
  color: #fff;
  margin-top: 25%;
  display: block;
  text-transform: uppercase;
  text-align: center;
}

.our-projects-home-box .project-hover .project-home-description span {
  font-size: 18px;
  display: block;
  margin: 15px 0 10px;
}

.our-projects-home-box .project-hover .project-home-description i {
  font-size: 21px;
}

.all-link {
  text-align: center;
  padding: 50px;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

/*** Counter ***/

.counter-line {
  padding: 40px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  /*background: #0275d8;
    color: #fff;*/
  background: #f0f0f0;
  color: #4d4d4d;
}

.counter {
  font-size: 36px;
  margin-bottom: 15px;
  font-weight: 300;
}

.counter-line span {
  display: block;
}

/*** Flex ***/

.equal,
.equal > div[class*="col-"] {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: 1 1 auto;
}

/*** blog home ***/

.blog-section {
  padding-top: 60px;
  padding-bottom: 50px;
}

.blog-section_box {
  margin: 0 15px;
}

.blog-section h2 {
  text-align: center;
}

.blog-section h4 {
  height: 44px;
}

.blog-section h4 a {
  font-size: 16px;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 24px;
}

.blog-section h4 a:hover {
  color: #0275d8;
}

.flex-blog {
  -moz-column-gap: 40px;
  -webkit-column-gap: 40px;
  column-gap: 40px;
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 2;
}

.bl-box {
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 70px;
}

.last-posts {
  margin-top: 60px;
  border-top: 1px solid #dfdfdf;
  padding-top: 40px;
}

.last-posts .bl-box {
  width: 50%;
  float: left;
  padding-right: 20px;
  padding-left: 0;
  box-sizing: border-box;
  margin-bottom: 0;
  box-sizing: border-box;
}

.last-posts .bl-box:last-of-type {
  padding-left: 20px;
  padding-right: 0;
}

.bl-box img {
  width: 100%;
}

.bl-box h4 {
  margin-top: 24px;
}

.bl-box p {
  line-height: 1.6;
}

.rdopmenu h3 {
  margin-top: 0;
  font-size: 18px;
}

.rdopmenu h3 span {
  margin-right: 10px;
}

.rdopmenu ul li {
  background: none;
  margin: 0 0 2px;
  font-weight: normal;
  padding: 0 0 0 25px;
}

.rdopmenu .rdopmenu_box,
.fbbox {
  margin-bottom: 30px;
}

/*** Testimonials ***/

.testimonials {
  padding: 60px 0;
  text-align: center;
}

.testimonials-box blockquote {
  font-size: 16px;
  border-left: 0px solid transparent;
  line-height: 28px;
  text-align: center;
}

.testimonials-item cite:before,
.testimonials-item div.credit:before {
  content: "" !important;
}

.testimonials-item .join-title:before,
.testimonials-item .join-location:before,
.testimonials-item .join:before {
  content: "";
}

.testimonials-item .author {
  display: block;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
}

.testimonials-item .job-title {
  display: block;
  text-align: center;
  font-size: 14px;
  color: #999;
}

/*.bx-wrapper .bx-controls-direction a{
    position: absolute;
    width: 40px;
    height: 40px;
    background: #fff;
    padding-top: 7px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border: 1px solid #e1e1e1;
    top: 50%;
    margin-top: -20px;
    opacity: 0;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    -ms-transition-duration: 0.4s;
    transition-duration: 0.4s;
}
.bx-wrapper .bx-controls-direction a:hover{
    background: #f5f5f5;
}*/

.paralax-home .bx-wrapper .bx-pager,
.paralax-home .bx-wrapper .bx-controls-auto {
  bottom: -30px;
}

.testimonial_carousel_element .pagination a.bx-next {
  right: 0px;
}

.testimonial_carousel_element .pagination a.bx-prev {
  left: 0px;
}

.testimonials:hover .bx-wrapper .bx-controls-direction a.bx-prev {
  left: -60px;
  opacity: 1;
}

.testimonials:hover .bx-wrapper .bx-controls-direction a.bx-next {
  right: -60px;
  opacity: 1;
}

.ercont {
  margin-top: 60px;
  padding: 80px 0;
}
/*** Leftside Nav ***/

/*
.left-nav {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100%;
    width: 60px;
    -webkit-transition: all 0.7s cubic-bezier(0.7, 0, 0.3, 1);
    transition: all 0.7s cubic-bezier(0.7, 0, 0.3, 1);
}
.left-nav:hover {
    width: 200px;
    overflow: visible;
}
.left-nav::after {
    width: 546px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background: url(../images/left-nav-bg.png);
    opacity: 0;
    pointer-events: none;
    content: '';
    position: absolute;
    display: block;
}
.left-nav:hover::after {
    opacity: 1;
    -webkit-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
}
.list {
    position: absolute;
    z-index: 1;
    top: 50%;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    left: 50px;
    list-style: none;
    padding: 0;
    margin: 0;
}
.item {
    position: relative;
    padding-left: 22px;
    margin-bottom: 16px;
    cursor: pointer;
    line-height: 13px;
}
.link {
    font-size: 12px;
    color: #4d4d4d;
    text-transform: uppercase;
    line-height: 14px;
    white-space: nowrap;
    opacity: 0;
    text-decoration: none;
}
.left-nav:hover .link {
    opacity: 1;
    pointer-events: auto;
    -webkit-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
    transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
}
.item:hover .link {
    color: #0275d8;
}
.dot, .dot-hover {
    position: absolute;
    display: block;
    border-radius: 50%;
    top: 50%;
    left: 0;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    -webkit-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
    transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
}
.dot {
    width: 8px;
    height: 8px;
    background-color: rgba(136, 136, 136, 0.9);
}
.item:hover .dot {
    background-color: #0275d8;
}
.dot, .dot-hover {
    position: absolute;
    display: block;
    border-radius: 50%;
    top: 50%;
    left: 0;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    -webkit-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
    transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
}
.dot-hover {
    width: 16px;
    height: 16px;
    border: 1px solid transparent;
}
.item.active .dot-hover {
    background-color: #0275d8;
}
.item:hover .dot-hover {
    border-color: rgba(255, 255, 255, 0.5);
}
*/

/* Left Anchor Menu */

.site-navigation__pagination {
  /*position: fixed;
    top: 0;
    left: 25px;
    bottom: 0;
    margin: auto;
    height: 100px;
    z-index: 500*/
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 60px;
  -webkit-transition: all 0.7s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 0.7s cubic-bezier(0.7, 0, 0.3, 1);
}
.site-navigation__pagination:hover {
  width: 200px;
  overflow: visible;
}
.site-navigation__pagination:hover::after {
  opacity: 1;
  -webkit-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
}
.site-navigation__pagination:after {
  width: 546px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  // background: url(../images/left-nav-bg-w.png) center;
  opacity: 0;
  pointer-events: none;
  content: "";
  position: absolute;
  display: block;
}

.site-navigation__pagination__items {
  /*height: 100px*/
  position: absolute;
  z-index: 1;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  left: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.site-navigation__pagination__item {
  height: 34px;
  position: relative;
}

.site-navigation__pagination__button {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

/*.site-navigation__pagination__button__dot {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 6px;
    height: 6px;
    background: url(../images/left-menu-img.png) center center no-repeat;
    transition: all .2s ease-in-out;

.site-navigation__pagination__item:hover .site-navigation__pagination__button__dot {
    background: url(../images/left-menu-img-hover.png) center center no-repeat;
}*/
.site-navigation__pagination__button__dot:after {
  content: "\f10c";
  font-family: FontAwesome;
  font-style: normal;
  font-size: inherit;
  font-weight: normal;
  text-decoration: inherit;
  color: #0275d8;
  position: absolute;
  top: 18%;
  /*top: 50%;
    transform: translateY(-40%);*/
  left: 12px;
}
.site-navigation__pagination__item:hover
  .site-navigation__pagination__button__dot:after {
  content: "\f111";
}

.site-navigation__pagination__active {
  position: absolute;
  top: 0;
  left: 0;
}

.site-navigation__pagination__item .site-navigation__pagination-tooltip__line {
  position: absolute;
  /*top: 15px;*/
  top: 50%;
  left: 30px;
  width: 0px;
  height: 1px;
  background-color: #0275d8;
  transition: all 0.4s ease-in-out;
}
.site-navigation__pagination__item:hover
  .site-navigation__pagination-tooltip__line {
  width: 30px;
}

.site-navigation__pagination__item .site-navigation__pagination-tooltip__text {
  position: absolute;
  top: 0;
  left: 50px;
  text-align: left;
  line-height: 34px;
  font-size: 13px;
  font-style: italic;
  white-space: nowrap;
  width: 0px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.site-navigation__pagination__item:hover
  .site-navigation__pagination-tooltip__text {
  left: 70px;
  width: 250px;
  transition: all 0.2s ease-in-out;
}

/*** End Leftside Nav ***/



/****  Contact us   ****/

.contact-form {
  background: #f5f5f5;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.contact-form .form-group {
  overflow: hidden;
}

.contact-form .form-control,
#header-modal .form-control,
.modal-body .form-control {
  border-radius: 0;
  height: 40px;
}
input,
textarea {
  -webkit-appearance: none;
}
.contact-form textarea.form-control {
  border-radius: 0px;
  height: 150px;
}

.contact-form .index-form {
  margin-top: 30px;
}

.modal-content {
  border-radius: 0;
  border: 4px solid #0275d8;
}
.modal-content .errors {
  text-align: center;
  color: red;
  font-weight: normal;
}
.modal-content .thanks {
  text-align: center;
}
.modal-content .thanks-close {
  text-align: center;
  margin-top: 20px;
  display: block;
}
.modal-title {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  padding: 15px 0 15px 15px;
  font-size: 24px;
  width: 100%;
  font-weight: bold;
  font-family: $font-title;
}
.modal-backdrop.in {
  filter: alpha(opacity=70);
  opacity: 0.7;
}
.modal-header {
  border: none;
  border-radius: 0;
  padding: 0;
  margin-bottom: 10px;
  background: #0275d8;
}

#header-modal .form-group,
#qvestion-modal .form-group {
  overflow: hidden;
}

#header-modal textarea.form-control {
  border-radius: 0px;
  height: 80px;
}

#header-modal .submit-index,
#qvestion-modal .submit-index {
  text-align: center;
  margin-bottom: 0;
}

#header-modal .modal-close-link,
.modal-body .modal-close-link {
  color: #ccc;
  margin-top: 7px;
  display: block;
}
.modal-body .submit-index {
  margin-bottom: 0;
}
#header-modal .modal-close-link:hover,
.modal-body .modal-close-link:hover {
  color: #808080;
}
#recaptcha_modal > div {
  margin: 0 auto 10px;
}



.submit-index {
  padding-bottom: 2px;
}

/*INNER PAGE*/

.page-title-theme {
  background: url(../images/page-slide-4.jpg) no-repeat;
  background-position: top center;
  background-size: cover;
  padding: 20px 0;
  color: #fff;
  /*    margin-bottom: 40px;*/
  margin-top: 62px;
}

.page-title-theme_one-service {
  /* background: url(../images/bg_service_site_fix.jpg) top center no-repeat;
    background-attachment: fixed; */
  position: relative;
  @include gradient-hero3;
  background-size: 600% 600%;
  animation: Gradient 16s ease infinite;
  transition-duration: 0.95s;
  transition-property: left;
  transition-delay: 0s;
  transition-timing-function: "ease-in-out";
  padding: 30px 0;
  margin-bottom: 20px;
  margin-top: 62px;
}

.page-title-theme h1,
.page-title-theme_one-service h1 {
  margin: 20px 0;
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  color: #fff;
}

.page-title-theme_one-business {
  padding: 120px 0;
  margin-bottom: 60px;
  margin-top: 62px;
  height: auto;
  text-align: center;
  color: #fff;
}

.page-title-theme_one-business.hotel {
  background: url(../images/hotel_bg_top_fix.jpg) top center no-repeat;
  background-attachment: fixed;
}

.page-title-theme_one-business.travel {
  background: url(../images/travel_bg_top_fix.jpg) top center no-repeat;
  background-attachment: fixed;
}

.page-title-theme_one-business.education {
  background: url(../images/courses_bg_top_fix.jpg) top center no-repeat;
  background-attachment: fixed;
}

.page-title-theme_one-business.medical {
  background: url(../images/medicine_bg_top_fix.jpg) top center no-repeat;
  background-attachment: fixed;
}

.page-title-theme_one-business.charity {
  background: url(../images/charity_bg_top_fix.jpg) top center no-repeat;
  background-attachment: fixed;
}

.page-title-theme_one-business.service {
  background: url(../images/services_bg_top_fix.jpg) top center no-repeat;
  background-attachment: fixed;
}

.page-title-theme_one-business h1 {
  margin: 10px 0;
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 700;
}

.business-page_txt-line h2 {
  line-height: 44px;
  margin-bottom: 20px;
}

.business-page_txt-line {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.delimiter {
  width: 100%;
  margin: 40px 0;
  position: relative;
}

.delimiter:after {
  content: "";
  width: 80px;
  border-bottom: 1px solid #0275d8;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -40px;
}

.business-page_process {
  text-align: center;
  background: #f5f5f5;
  padding: 50px 0 60px;
  margin: 50px 0 0;
}

.business-page_process-step {
  margin: 40px 0 0;
}

.business-page_process-step .dl-horizontal dt {
  width: 42px;
  height: 42px;
}

.business-page_process-step .dl-horizontal dd {
  margin-left: 18px;
  text-align: left;
}

.business-page_process h3 {
  margin-bottom: 25px;
}

.business-page_process-step h4 {
  font-size: 16px;
  margin-top: 0;
  font-weight: bold;
  text-transform: uppercase;
}

.dl-horizontal.with_icon .fa {
  color: $color-link;
}

.business-page_process .btn-margin {
  margin: 40px 0 10px;
}

.business-page_process .btn-margin .btn-default {
  background: #f5f5f5;
}

.business-page_process .btn-margin .btn-default:hover {
  background: #0275d8;
}

.business-page_planes {
  width: 100%;
  padding: 50px 0 70px;
  background: url(../images/bg_price_fix-2.jpg) top center no-repeat;
  background-attachment: fixed;
}

.business-page_planes-box {
  margin: 0 10px;
  padding: 0;
  background: #fff;
}

.business-page_planes-box:hover {
  -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
}

.business-page_planes-box h4 {
  color: #fff;
  background: #0275d8;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  padding: 15px 0;
  text-transform: uppercase;
  margin-bottom: 0;
}

.business-page_planes-box h4 .fa {
  margin-right: 7px;
}

.business-page_planes-box p {
  padding: 20px 40px 10px;
  min-height: 210px;
  text-align: center;
}

.business-page_planes-box .business-page_planes-term {
  padding: 10px;
  text-align: center;
  background: #4d4d4d;
  color: #fff;
}

.business-page_planes-box .business-page_planes-price {
  padding: 20px 0;
  text-align: center;
  font-size: 36px;
  font-weight: normal;
  /*background: #f5f5f5*/
}

.business-page_planes-box .business-page_planes-btn {
  text-align: center;
  padding: 0 0 25px;
  /*background: #f5f5f5*/
}

.business-page_planes-box .business-page_planes-btn .btn-default {
  /*background: #f5f5f5;*/
  border: 1px solid #0275d8;
}

.business-page_planes-box .business-page_planes-btn .btn-default:hover,
.business-page_planes-box .business-page_planes-btn .btn-default:active,
.business-page_planes-box .business-page_planes-btn .btn-default:focus {
  background: #0275d8;
  border: 1px solid #0275d8;
}

.business-page_planes h3 {
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 30px;
}

.business-page_planes table {
  width: 100%;
}

.business-page_planes table thead {
  color: #0275d8;
  font-weight: 700;
}

.business-page_planes table tr td {
  background: #fff;
  text-align: center;
  padding: 8px 20px;
  border-bottom: 1px solid #dfdfdf;
}

.business-page_planes table tr td:nth-of-type(1) {
  background: transparent;
  text-align: left;
  border-bottom: 1px solid #4a4a4a;
  color: #000;
  font-weight: 700;
}

/*** Sait na Vchera ***/

.saitnavchera_box {
  position: relative;
  @include gradient-hero3;
  background-size: 600% 600%;
  animation: Gradient 16s ease infinite;
  transition-duration: 0.95s;
  transition-property: left;
  transition-delay: 0s;
  transition-timing-function: "ease-in-out";
  padding: 25px;
}

.saitnavchera_box h2 {
  color: #fff;
  margin: 15px 30px 20px;
}

.saitnavchera_box a {
  margin: 15px 30px;
}

/*** Projects Business ***/

.projects-business {
  display: block;
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 0;
  /*background: #f0f0f0;*/
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
}

.projects-business .bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  bottom: -50px;
}

.line-one h2.page-subtitle {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  margin-top: 40px;
  padding-bottom: 20px;
}

.line-one .col-sm-6 + .col-sm-6 {
  text-align: left;
}

.box-content {
  margin: 50px 0px 80px;
}

.contact-map {
  position: relative;
  overflow: hidden;
}

.contact-map .contact-form {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
}

.contact-info-box {
  background: #e5e5e5;
  position: absolute;
  bottom: 64px;
  width: 100%;
  padding-left: 550px;
  padding: 14px 0;
}

.contact-info-box-info {
  position: relative;
  left: 50%;
  margin-left: -50px;
}

.map {
  height: 450px;
  overflow: hidden;
}

.map .row .col-md-12 {
  padding-right: 0;
  padding-left: 0;
}

.contact-text {
  border: 1px solid #dfdfdf;
  padding: 40px;
  text-align: center;
  box-sizing: border-box;
  font-size: 16px;
}

.col-sm-6:nth-of-type(1) .contact-text {
  margin: 20px 10px 50px 30px;
}

.col-sm-6:nth-of-type(2) .contact-text {
  margin: 20px 30px 50px 10px;
}

.contact-text h2 {
  color: #4d4d4d;
  font-weight: 700;
  margin-bottom: 0 0 40px;
}

// .contact-text:hover {
//   background: #0275d8;
//   border-color: #0275d8;
//   color: #fff;
// }

// .contact-text:hover h2,
// .contact-text:hover a {
//   color: #fff;
// }

// .contact-text:hover a {
//   text-decoration: underline;
// }

/* FLEX-BOX */

.flex-row {
  display: flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: -webkit-block;
  flex-direction: row;
  -ms-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -ms-align-items: stretch;
  -moz-align-items: stretch;
  -webkit-align-items: stretch;
  letter-spacing: -0.36em;
}
.flex-box {
  padding: 30px !important;
  display: flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  /*    display: block;*/
  flex-direction: column;
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: center;
  -ms-align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
}
.flex-box ul {
  display: flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: block;
  flex-direction: row;
  -ms-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  -ms-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -webkit-justify-content: flex-start;
  overflow: hidden;
}
.service-page-sd-bg img,
.service-page-sd-bg-2 img,
.service-page-sd-bg-3 img,
.service-page-sd-bg-4 img {
  opacity: 0;
  width: 100%;
}
.flex {
  width: 50%;
  letter-spacing: 0;
  display: inline-block;
}
.flex-justify {
  display: flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: -webkit-block;
  justify-content: center;
  -ms-justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
}
.flex-row .inside-box {
  display: flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: -webkit-block;
  flex-direction: column;
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
}
@media (max-width: 1119px) {
  .flex-box ul {
    justify-content: center;
    -ms-justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
  }
}
@media (max-width: 767px) {
  .navbar-nav {
    flex-direction: column;
    -ms-flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
  }
  .flex-row {
    flex-direction: column;
    -ms-flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    align-items: center;
    -ms-align-items: center;
    -moz-align-items: center;
    -webkit-align-items: center;
  }
  .flex {
    width: 100%;
  }
  .flex-row .service-page-sd-bg-2,
  .flex-row .service-page-sd-bg-4 {
    order: -1;
  }
  .flex-box ul {
    justify-content: flex-start;
    -ms-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -webkit-justify-content: flex-start;
  }
  .flex-box ul li {
    margin: 0;
  }
}
@media (max-width: 559px) {
  .flex-box ul {
    justify-content: center;
    -ms-justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
  }
}

/* FLEX-BOX END */

/*INNER PAGE END*/

/* Page */

/* Tables */

.content table {
  margin: 0 0 20px 0;
  width: 100%;
}

table.tehtabl,
table.st {
  text-align: center;
  font-size: 14px;
  margin: 0 0 60px;
}

table.tehtabl .tehtitl {
  font-weight: normal;
  background: #e5e5e5;
}

table.tehtabl .tehtitl td {
  color: #666;
}

table.tehtabl tr:nth-child(1),
table.st tr:nth-child(1) {
  background: #0275d8;
  color: #fff;
}

table.tehtabl tr:nth-child(1) td,
table.st tr:nth-child(1) td {
  color: #fff;
  padding: 15px 8px;
}

table.st td:nth-child(1) {
  text-align: left;
}

table.st tr:nth-child(2n + 2) {
  background: #f0f0f0;
  color: #666;
}

table.st tr:nth-child(22) td {
  color: #666;
  font-weight: bold;
}

table.tehtabl tr:nth-child(2) {
  background: #fafafa;
  color: #666;
}

table.tehtabl tr:nth-child(2) td {
  color: #666;
  font-weight: bold;
}

table.st th {
  padding: 6px 6px 6px 6px;
  background-color: transparent;
  color: #666;
  font-weight: bold;
  border: 1px solid #dfdfdf;
}

table.st td {
  padding: 6px 8px 6px 8px;
  background-color: transparent;
  color: #666;
  border: 1px solid #dfdfdf;
  line-height: 16px;
  vertical-align: middle;
  min-height: 40px;
}

table th {
  padding: 8px 6px 8px 6px;
  background-color: transparent;
  color: #666;
  font-weight: bold;
  border: 1px solid #dfdfdf;
}

table td {
  padding: 10px 8px 10px 8px;
  background-color: transparent;
  color: #666;
  border: 1px solid #dfdfdf;
}

table tr.even td {
  background-color: transparent;
}

table tr.odd td {
  background-color: transparent;
}

table td.highlight {
  color: #fff;
  font-weight: bold;
}

table tr td.highlight {
  background-color: #333;
}

/*POSTS*/

h1.page-title a {
  margin-top: 0px;
  color: #0275d8;
  text-decoration: none;
}

h1.page-title {
  color: #0275d8;
  border-bottom: 1px solid #0275d8;
  padding-bottom: 7px;
}

.entry-meta {
  display: none;
}

/*POSTS END*/

/*** Breadcrumb ***/

#breadcrumb,
#breadcrumb a,
#breadcrumb span span {
  color: #999;
  font-size: 12px;
  text-transform: uppercase;
  margin: 10px auto;
  padding: 0 8px;
}

#breadcrumb a:hover {
  text-decoration: underline;
}

/*** Service page ***/

h2.page-subtitle {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  padding: 0 0 40px;
}

.line-one {
  text-align: center;
  margin-top: 40px;
}

.line-two {
  background: #f0f0f0;
  margin: 0;
  padding-top: 70px;
  padding-bottom: 80px;
  /*    text-align: center*/
}

.line-two h3 {
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 30px;
}

.line-tree {
  padding-top: 80px;
  padding-bottom: 40px;
}

.line-four {
  margin: 40px auto;
}

.work-example {
  margin: 60px 0;
  text-align: center;
  background: url(../images/slide-example/mac-bg.png) top center no-repeat;
}

.work-example_box {
  max-width: 815px;
  margin: 0 auto;
  padding: 35px 0 100px 7px;
}

.service-tabs .nav-tabs {
  border-bottom: none;
}

.service-tabs .nav-tabs > li {
  width: 33.3%;
  text-align: center;
  height: 85px;
  position: relative;
  z-index: 10;
}

.service-tabs .nav-tabs > li:last-of-type {
  width: 33.4%;
}

.service-tabs .nav-tabs > li.active > a,
.service-tabs .nav-tabs > li.active > a:focus,
.service-tabs .nav-tabs > li.active > a:hover,
.service-tabs .nav-tabs > li:hover a {
  cursor: default;
  /*background-color: #0275d8;
    border: 1px solid #0275d8;
    border-bottom-color: transparent;
    color: #fff;*/
  background: #fff;
  color: #0275d8;
  border-radius: 0;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid #e0e0e0;
}

.service-tabs .nav-tabs > li.active:before,
.service-tabs .nav-tabs > li:hover:before {
  background: #0275d8;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 3px;
  width: 100%;
  display: block;
  content: "";
  position: absolute;
  z-index: 20;
}

.service-tabs .nav > li > a {
  padding: 15px;
}

.service-tabs .nav-tabs > li > a > div > span {
  font-size: 21px;
  display: block;
  margin-bottom: 10px;
}

.service-tabs .nav-tabs > li.active > a > div > span {
  color: #0275d8;
}

.service-tabs .nav-tabs > li > a {
  margin-right: -1px;
  line-height: 1.42857143;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  color: #4d4d4d;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  background: #f5f5f5;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.service-tabs .nav-tabs > li:last-of-type > a {
  margin-right: 0;
}

.service-tabs .tab-content {
  /*border-top: 1px solid #0275d8;*/
  padding: 30px 0 25px;
}

.service-special-link {
  background: #f5f5f5;
  margin: 0;
  padding-top: 70px;
  padding-bottom: 70px;
  text-align: center;
}

.special-link-img {
  width: 120px;
  height: 120px;
  border: 2px solid #0275d8;
  border-radius: 50%;
  margin: 0 auto 20px;
  text-align: center;
  padding-top: 35px;
}

.special-link-img span {
  display: block;
  font-size: 48px;
  color: #0275d8;
}

.special-link-img:hover {
  background: #0275d8;
}

.special-link-img:hover span {
  color: #fff;
}

.service-special-link .col-sm-2 {
  text-align: center;
}

.service-list-box {
  /*padding: 60px 10% 80px;*/
  padding: 120px 10% 160px;
  margin: 0 auto;
}

.service-list-box .dot-line {
  margin: 30px 0 30px;
}

/*.service-list-box li {
    padding: 15px 0;
    border-bottom: 1px solid #ccc;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.service-list-box li:hover {
    padding: 15px 0 15px 15px
}*/

.service-list-box li {
  padding: 8px 0;
  margin: 0 5%;
  width: 40%;
  float: left;
  min-width: 200px;
  border-bottom: 1px solid #ccc;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.service-list-box li:hover {
  padding: 8px 0 8px 10px;
}

.service-page-sd-bg {
  background: url(../images/ctreate_site_bg.jpg) 100% 50% no-repeat;
  background-size: cover;
}

.service-page-sd-bg-2 {
  background: url(../images/support_site_bg.jpg) 100% 50% no-repeat;
  background-size: cover;
}

.service-page-sd-bg-3 {
  background: url(../images/poligrafiya_site_bg-3.jpg) 100% 50% no-repeat;
  background-size: cover;
}

.service-page-sd-bg-4 {
  background: url(../images/design_site_bg.jpg) 100% 50% no-repeat;
  background-size: cover;
}

.line-tree {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      padding: 0 0 0 25px;
      margin: 0 0 10px 0;
      position: relative;
    }
  }
}

.line-tree li:before {
  content: "\f00c";
  position: absolute;
  left: 0;
  top: 7px;
  width: 20px;
  height: 20px;
  color: #0275d8;
  font-family: FontAwesome;
  font-weight: 400;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}


.txt-page-content {
  padding-bottom: 60px;
}

.txt-page-content li,
.check-style li {
  // padding-left: 24px;
  margin-bottom: 5px;
}

// .txt-page-content li:before,
// .check-style li:before {
//   content: "\e080";
//   margin-left: -24px;
//   margin-right: 10px;
//   color: #0275d8;
//   font-family: "Simple-Line-Icons";
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;
//   -webkit-font-smoothing: antialiased;
// }

/*** Animation effects ***/

@-webkit-keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

@keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

.hvr-ripple-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

.hvr-ripple-out:before {
  content: "";
  position: absolute;
  border: #0275d8 solid 6px;
  border-radius: 50%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.hvr-ripple-out:hover:before,
.hvr-ripple-out:focus:before,
.hvr-ripple-out:active:before {
  -webkit-animation-name: hvr-ripple-out;
  animation-name: hvr-ripple-out;
}

/*** Project Page ***/

.project-info h2 {
  margin-bottom: 10px;
  font-size: 21px;
  font-weight: bold;
  font-family: $font-title;
  text-transform: uppercase;
}

.project-info .blue-bg {
  color: #fff;
}

.project-info h3 {
  margin: 0 0 15px;
}

.project-info .white-bg .blue-bg li:before {
  color: #fff;
}

.project-info .white-bg li:before {
  color: #0275d8;
}

.project-bigimg {
  margin: 0;
  overflow: hidden;
  .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  img {
    width: 100%;
  }
  &__bg {
    padding: 80px 0;
  }
  &__full {
    margin: 0 -15px;
    box-shadow: 0px 0px 30px 0px rgba(0,113,188,0.3);
  }
}
.project-page-img {
  margin-top: 40px;
}

.project-page-img img {
  // -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
  // -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
  // box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 1400px;
}

.hr-line {
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #ddd;
  margin: 40px 0;
}

/* FAQ */

.faq-tabs {
  margin: 60px 0;
}

.faq-tabs .panel-default {
  border-bottom: 1px solid #ddd;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.faq-tabs .panel-default,
.faq-tabs .panel-default > .panel-heading {
  background-color: #fff;
}

.faq-tabs .panel {
  box-shadow: none;
}

.panel-title a {
  text-decoration: none;
  color: $color-text;
  display: block;
  &:hover {
    color: #0055cc;
  }
}

.panel-body {
  padding: 10px 15px 30px 0;
  margin-left: 55px;
}

.faq-box .panel-title {
  padding: 6px 32px;
  position: relative;
}

.faq-tabs a.collapsed:before {
  content: "\f138";
  color: #666;
  font-family: FontAwesome;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
}
.faq-tabs a:hover.collapsed:before {
    color: #0055cc;
  }

.faq-tabs a:before {
  content: "\f13a";
  color: #666;
  font-family: FontAwesome;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 10px;
  left: 0px;
}
.faq-tabs a:hover:before {
    color: #0055cc;
  }

/*** Mobile ***/
@media (max-width: 768px) {
  /*.equal,
    .equal > div[class*='col-'] {
        display: block;
    }*/
  .whore-clients-bgimage {
    min-height: 300px;
  }
  // .header {
  //   background: rgba(0, 0, 0, 0.8);
  // }
  .navbar-toggle {
    border: 1px solid #fff;
    position: absolute;
    right: 0;
    top: -55px;
  }
  .navbar-toggle .icon-bar {
    background: #fff;
  }
  .menu-header {
    padding: 0px 20px;
  }
  .counter-line .col-md-4 {
    margin-bottom: 40px;
  }
  .counter-line .col-md-4:last-child {
    margin-bottom: 0;
  }
  .counter {
    margin-bottom: 0;
  }
  .our-projects-home-box > img {
    width: 100%;
  }
  .logo-header,
  .logo-fixed {
    padding: 12px;
  }
  #navbar {
    float: none;
  }
  .navbar-nav > li > a {
    padding: 15px 0 15px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .navbar-nav > li:last-of-type > a {
    border-bottom: none;
  }

  .navbar-collapse {
    border-top: none;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0);
  }
  .navbar-collapse.collapse.in {
    transition: all 0.3s ease-in-out !important;
    transform: translate(0px) !important;
    -ms-transform: translate(0px) !important;
    -webkit-transform: translate(0px) !important;
    transform: translate(220px);
    -ms-transform: translate(220px);
    -webkit-transform: translate(220px);
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
  }
  .page-title-theme h1 {
    text-align: center;
  }
  /*.video-box {
        display: none
    }*/
  .our-services {
    padding-top: 70px;
    padding-bottom: 60px;
  }
  .servces-box {
    padding: 0 40px 30px;
  }
  .why-work,
  .services-home {
    padding: 10px 0;
  }
  .blog-section .col-md-4 {
    margin-bottom: 40px;
  }
  .contact-form .form-group .col-md-6:first-child {
    margin-bottom: 15px;
  }
  .contact-text {
    padding: 15px 15px;
  }
  .contact-text h2 {
    line-height: 40px;
    margin-bottom: 0 0 30px;
  }
  .col-sm-6:nth-of-type(1) .contact-text,
  .col-sm-6:nth-of-type(2) .contact-text {
    margin: 0 10px 30px;
  }
  footer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .logo-footer {
    margin: 0 0 15px;
  }
  .menu-footer {
    margin: 0 0 15px;
  }
}

/* MEDIA-QUERIES */

@media (max-width: 991px) {
  .line-one .row,
  .line-tree .row {
    display: flex;
    -webkit-display: flex;
    -moz-display: flex;
    -ms-display: flex;
    -o-display: flex;
    align-items: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
  }
  .line-tree .row img {
    width: 100%;
  }
  .video-box .video-box-title {
    font-size: 20px;
    line-height: 24px;
  }
  .video-box .video-box-title h2 {
    font-size: 25px;
    line-height: 38px;
    margin: 0;
    margin-bottom: 20px;
  }
  .video-box .video-box-title h2 + div {
    margin: 0 0 30px;
  }
  .video-box .video-box-out {
    margin-bottom: -7px;
  }
  .video-box {
    height: auto;
  }
  .logo-header,
  .logo-fixed {
    padding: 12px 0;
  }
  .navbar-nav > li > a {
    padding: 15px 5px;
  }
  .navbar-nav > li > a {
    border-bottom: 0 !important;
  }
  .btn {
    padding: 10px 40px;
  }
  .site-navigation__pagination .site-navigation__pagination__items {
    left: 0;
  }
  .site-navigation__pagination:hover
    .site-navigation__pagination__item
    .site-navigation__pagination-tooltip__line {
    width: 30px;
  }
  .site-navigation__pagination:hover
    .site-navigation__pagination__button__dot:after {
    content: "\f111";
  }
  .site-navigation__pagination:hover
    .site-navigation__pagination__item
    .site-navigation__pagination-tooltip__text {
    left: 70px;
    width: 250px;
    transition: all 0.2s ease-in-out;
  }

  #main {
    margin-top: -12px;
  }
  .our-services {
    padding-top: 90px;
    padding-bottom: 40px;
  }
  h2 {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .our-services h3 {
    font-size: 16px;
  }
  .our-services img {
    width: 60px;
  }
  .our-services .text-center {
    margin-top: 20px;
  }
  .our-projects-home h2 {
    margin: 25px;
  }
  .our-projects-home-box > img {
    display: block;
    margin: 0 auto;
  }
  .all-link {
    padding: 30px;
  }
  .counter-line {
    padding: 25px;
  }
  .counter-line .counter {
    margin-bottom: 7px;
    font-size: 30px;
  }
  .inside-box {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .blog-section,
  .contact-form {
    padding-top: 30px;
    padding-bottom: 25px;
  }
  .blog-section_box {
    text-align: center;
  }
  .blog-section_box img,
  .tab-content img {
    display: block;
    margin: 0 auto 10px;
  }
  #footer {
    text-align: center;
  }
  .blog-section .col-md-4 {
    margin-bottom: 30px;
  }
  .page-title-theme h1,
  .page-title-theme_one-service h1 {
    font-size: 30px;
  }
  .service-tabs .nav > li > a {
    padding: 10px;
  }
  .service-tabs .tab-content {
    padding: 15px 20px;
  }
  .service-special-link {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .special-link-img {
    width: 80px;
    height: 80px;
    padding-top: 26px;
    border-width: 1px;
    margin-bottom: 10px;
  }
  .special-link-img span {
    font-size: 30px;
  }
  .service-special-link .col-sm-4 {
    margin-bottom: 20px;
  }
  .service-special-link .col-sm-4 a,
  .service-special-link .col-sm-4 span {
    display: block;
  }
  .service-special-link .col-sm-4 a {
    text-align: center;
  }
  .section-space,
  .light-grey-box {
    padding-top: 25px;
    padding-bottom: 30px;
  }
  #breadcrumb {
    margin: 20px auto;
  }
  .rdopmenu h3 {
    font-size: 16px;
  }
  .hr-line {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .col-sm-6:nth-of-type(1) .contact-text,
  .col-sm-6:nth-of-type(2) .contact-text {
    margin-top: 20px;
  }
  .contact-text {
    height: 250px;
  }
  .contact-text h2 {
    font-size: 18px;
    line-height: 30px;
  }
  .map,
  .map iframe {
    height: 300px !important;
  }
  .servces-box h3 {
    margin-top: 15px;
  }
  table.tehtabl tr:nth-child(1) td,
  table.st tr:nth-child(1) td {
    padding: 10px 6px;
  }
  .page-title-theme h1,
  .page-title-theme_one-service h1 {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  .page-title-theme_one-service {
    padding: 60px 0;
    height: 200px;
    margin-bottom: 20px;
  }
  .sign-up,
  .header-inner.dark-header .sign-up {
    margin-top: 14px;
  }
  .sign-up a.blue-btn {
    border-color: #fff;
    background: transparent;
    padding: 7px 20px;
  }
  .our-projects-home-box {
    margin: 0 auto;
  }
  .line-two {
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .line-two h3 {
    margin-bottom: 20px;
  }
  .line-tree {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .saitnavchera_box .text-left {
    text-align: center !important;
  }
  .projects-business {
    padding-top: 30px;
  }
  .dot-line {
    margin: 15px auto;
  }
  .line-one {
    margin-top: 40px;
    padding-bottom: 30px;
  }
  .line-one h2.page-subtitle {
    font-size: 20px;
    margin-top: 20px;
    padding-bottom: 0;
  }
  .flex-blog {
    column-count: 1;
    -webkit-column-count: 1;
  }
  .bl-box {
    padding-right: 0;
    margin-bottom: 25px;
  }
  .bl-box img {
    display: block;
    margin: 0 auto 10px;
  }
  .bl-box h4 {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .video-box .video-box-out {
    display: none;
  }
  .video-box {
    height: 360px;
  }
  .video-box .video-box-title {
    top: 30%;
  }
  .video-box .video-box-title h2 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 15px;
  }
  .video-box .video-box-title {
    font-size: 16px;
    line-height: 20px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  }
  .video-box .video-box-btn {
    display: -webkit-flex;
    display: -webkit-block;
    justify-content: space-between;
  }
  .video-box .video-box-btn .btn-outer {
    margin: 0 5px 10px !important;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 17px;
    width: 49%;
  }
  .business-page_planes-box p {
    min-height: auto;
    margin-bottom: 20px;
  }
  .line-one .row,
  .line-tree .row {
    display: block;
  }
  .business-page_process-step .dl-horizontal dt {
    display: block;
  }
  .our-projects-home-box img {
    height: auto;
  }
  .our-projects-home-box {
    margin: 0 auto;
  }
  .project-page-img {
    padding-left: 0;
    padding-right: 0;
  }
  .service-list-box li {
    width: 100%;
    text-align: center;
  }
  .site-navigation__pagination {
    display: none;
  }
  #navbar .nav > li {
    text-align: center;
  }
  .dark-header .logo-fixed {
    display: inline-block;
  }
  .logo-fixed a,
  .logo-header a {
    width: 100px;
    display: block;
  }
  #navbar {
    padding-bottom: 20px;
  }
  .sign-up {
    text-align: center;
  }
  .navbar-toggle {
    top: -50px;
  }
  .service-tabs .nav-tabs {
    margin: 0 10px;
  }
  .service-tabs .nav-tabs > li > a,
  .service-tabs .nav-tabs > li.active > a,
  .service-tabs .nav-tabs > li.active > a:focus,
  .service-tabs .nav-tabs > li.active > a:hover,
  .service-tabs .nav-tabs > li:hover a {
    font-size: 13px;
  }
  .saitnavchera_box .btn {
    padding: 10px 20px;
  }
  .saitnavchera_box a {
    margin: 0;
  }
  .projects-business {
    padding-top: 50px;
  }
  .txt-page-content {
    padding-bottom: 20px;
  }
  h2 {
    font-size: 21px;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .faq-tabs a.collapsed:before,
  .faq-tabs a:before {
    font-size: 14px;
    margin-right: 5px;
  }
  .panel-title a {
    font-size: 14px;
  }
  .faq-tabs {
    margin-bottom: 20px;
  }
  .copyright-box .socials ul li {
    float: none;
    display: inline-block;
    padding-right: 10px;
  }
  .page-title-theme h1,
  .page-title-theme_one-service h1 {
    font-size: 24px;
    line-height: 36px;
  }
  .page-title-theme_one-service {
    display: flex;
    align-items: center;
    padding: 10px 0 10px;
    height: auto;
  }
  #breadcrumb {
    margin: 20px auto 5px;
  }
  .contact-text {
    height: auto;
  }
  .section-space {
    padding-bottom: 0;
  }
  .navbar-nav > li > a {
    padding: 10px 5px;
  }

  .justify-center {
    text-align: left;
    text-align-last: left;
}
  .line-one p .btn {
    display: block;
    margin: 0 auto;
    width: 260px;
  }
  .line-two h3 {
    font-size: 18px;
    line-height: 30px;
  }
  .panel-body {
    margin-left: 25px;
  }
  #breadcrumb,
  #breadcrumb a,
  #breadcrumb span span {
    font-size: 10px;
    padding: 0 2px;
  }
  .equal,
  .equal > div[class*="col-"] {
    display: block;
  }
  #sh_button {
    display: none;
  }
  #footer {
    background-attachment: local;
  }
  .page-title-theme_one-business.hotel {
    background-attachment: local;
  }
  .page-title-theme_one-business {
    padding: 40px 0;
  }
  .page-title-theme_one-business h1 {
    font-size: 22px;
  }
  .business-page_txt-line h2 {
    line-height: 25px;
  }
  .dl-horizontal,
  .with_icon {
    display: flex;
    align-items: flex-start;
  }
}
