canvas {
  display: block;
  vertical-align: bottom;
}
/* ---- particles.js container ---- */
.particles {
  position: relative;
  width: 100%;
  height: 300px;
}
#particles-js,
#particles-js3,
#geometric {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}
.particles-bg {
  height: 100%;
  width: 100%;
  background: linear-gradient(245deg, #2a0aa9, #7c139d, #23075d);
  background-size: 600% 600%;
  /**/
   -webkit-animation: Gradient 16s ease infinite;
   -moz-animation: Gradient 16s ease infinite;
   animation: Gradient 16s ease infinite;
   /**/
  -webkit-transition-duration: .95s;
    -webkit-transition-property: left;
    -webkit-transition-delay: 0s;
    -webkit-transition-timing-function: 'ease-in-out';
    transition-duration: .95s;
    transition-property: left;
    transition-delay: 0s;
    transition-timing-function: 'ease-in-out';
}
